import { initializeApp } from 'firebase/app'
import { getDatabase, ref, child, get } from 'firebase/database'
import { developmentLog } from '@/shared/utils'
import picViewsFirebaseConfig from './pic-views-firebase-config.json'

class PicViewsRead {
  constructor() {
    this.firebaseApp = initializeApp(PicViewsRead.configByEnvironment())
    this.dbRef = ref(getDatabase(this.firebaseApp))
  }

  async viewsForUser(userId, callback) {
    // Finds pic views for a user and then fires a given callback
    get(child(this.dbRef, `pic_views/photographers/id-${userId}/total-views`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          callback(snapshot.val())
        } else {
          callback('-')
        }
      })
      .catch((error) => {
        developmentLog(`Problem fetching total pic views for a user: ${error}`)
      })
  }

  async viewsForPic(picId, callback) {
    // Finds views for a pic and then fires a given callback
    get(child(this.dbRef, `pic_views/pics/id-${picId}/total-views`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          callback(snapshot.val())
        }
      })
      .catch((error) => {
        developmentLog(`Problem fetching total pic views for a pic: ${error}`)
      })
  }

  static configByEnvironment() {
    const currentEnv = document.body.dataset.env

    if (currentEnv === 'test') return picViewsFirebaseConfig.development
    return picViewsFirebaseConfig[currentEnv]
  }
}

class PicViewsWrite {
  addPicView(url, jwt) {
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `pic_view_jwt=${encodeURIComponent(jwt)}`
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(`Unable to register pic view, status: ${response.status}, message: ${text}`)
          })
        }
      })
      .catch((error) => {
        developmentLog(error.message, true)
      })
  }
}

export { PicViewsRead, PicViewsWrite }
